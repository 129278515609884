import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { settingsRoutingModule } from '../../settings/settings.routes';
import { appsRoutingModule } from '../../myspace/routes/myspace.routes';
import { crmRoutingModule } from '../../crm/routes/crmdashboard.routes';
import { crmRouting2Module } from '../../crm/routes/crm.routes';
import { userProfilRoutingModule } from '../../user.profile/user-profile.routes';
import { supportRoutingModule } from '../../support/support.routes';
import { searchRoutingModule } from '../../search/search.routes';

export const content: Routes = [

  { path: '', children: [
    ...searchRoutingModule.routes,
    ...supportRoutingModule.routes,
    ...userProfilRoutingModule.routes,
   ...settingsRoutingModule.routes,
   ...appsRoutingModule.routes,
   ...crmRoutingModule.routes,
   ...crmRouting2Module.routes
  ]}
];

@NgModule({
    imports: [RouterModule.forRoot(content, {
      scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule]
})
export class SaredRoutingModule { }
