<section
  class="section landing-features calendar-section py-5"
  id="features"
>
  <div class="container">
    <!-- Titre et description -->
    <div class="text-center mb-5">
      <p [innerHTML]="invitationMessage"></p>
    </div>

    <!-- Si l'utilisateur n'a pas encore confirmé la réservation avec un code -->
    <ng-container *ngIf="!confirmed && !showConfirmationInput">
      <!-- Indicateur de chargement -->
      <div *ngIf="loading" class="text-center my-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Chargement...</span>
        </div>
      </div>

      <!-- Message d'erreur si aucune date disponible -->
      <div *ngIf="!loading && availableDays.length === 0" class="text-center my-5">
        <p class="text-danger">Aucune date disponible pour le moment.</p>
      </div>

      <!-- Boutons de navigation pour les semaines -->
      <div class="d-flex justify-content-center align-items-center mb-4 gap-2">
        <button (click)="navigateWeek(-1)" [disabled]="!canNavigateToPreviousWeek()" class="btn btn-outline-light">
          &#x2190;
        </button>
        <p class="mb-0 text-black mx-3">{{ currentWeekRange }}</p>
        <button class="btn btn-outline-light" (click)="navigateWeek(1)">
          &#x2192;
        </button>
      </div>

      <!-- Sélection des jours disponibles -->
      <div *ngIf="!loading && availableDays.length > 0" class="mb-4">
        <div class="d-flex flex-wrap justify-content-center gap-2">
          <button
            *ngFor="let day of availableDays"
            class="btn"
            [ngClass]="day.buttonClass || 'btn-outline-light'"
            [disabled]="!day.available || day.disabled"
            (click)="selectDay(day)"
          >
            {{ day.label }}
          </button>
        </div>
      </div>
      
      <!-- Sélection des heures disponibles -->
      <div *ngIf="selectedDay" class="mb-4">
        <div class="d-flex flex-wrap justify-content-center gap-2">
          <button
            *ngFor="let time of availableTimes"
            class="btn btn-outline-light"
            [disabled]="!time.available"
            [ngClass]="{ active: expandedTimeSlots.length && expandedTimeSlots[0].label.startsWith(time.label) }"
            (click)="expandTimeSlots(time)"
          >
            {{ time.label }}
          </button>
        </div>
      </div>
      
      <!-- Sélection des créneaux de 15 minutes -->
      <div *ngIf="expandedTimeSlots.length > 0" class="mb-4">
        <div class="d-flex flex-wrap justify-content-center gap-2">
          <button
            *ngFor="let slot of expandedTimeSlots"
            class="btn btn-outline-light"
            [disabled]="!slot.available"
            [ngClass]="{ active: selectedTime === slot.label }"
            (click)="selectTime(slot)"
          >
            {{ slot.label }}
          </button>
        </div>
      </div>
      
      <!-- Formulaire de réservation -->
      <div *ngIf="selectedTime && !loading" class="mb-5">
        <form [formGroup]="activityForm" (ngSubmit)="createActivity()" class="mx-auto" style="max-width: 600px;">
          <div class="row">
            <div *ngIf="clientFields?.name" class="mb-3 col-md-6">
              <label for="name" class="form-label text-black">Nom</label>
              <input
                type="text"
                id="name"
                formControlName="name"
                class="form-control"
                placeholder="Votre nom"
                required
              />
            </div>
            <div *ngIf="clientFields?.firstName" class="mb-3 col-md-6">
              <label for="firstName" class="form-label text-black">Prénom</label>
              <input
                type="text"
                id="firstName"
                formControlName="firstName"
                class="form-control"
                placeholder="Votre prénom"
                required
              />
            </div>
          </div>
          <div class="row">
            <div *ngIf="clientFields?.email" class="mb-3 col-md-6">
              <label for="email" class="form-label text-black">Email</label>
              <input
                type="email"
                id="email"
                formControlName="email"
                class="form-control"
                placeholder="Votre email"
                required
              />
            </div>
            <div *ngIf="clientFields?.phoneNumber" class="mb-3 col-md-6">
              <label for="phoneNumber" class="form-label text-black">Numéro de téléphone</label>
              <input
                type="tel"
                id="phoneNumber"
                formControlName="phoneNumber"
                class="form-control"
                placeholder="Votre numéro de téléphone"
                required
              />
            </div>
          </div>
          <div *ngIf="clientFields?.commentary" class="mb-3">
            <label for="commentary" class="form-label text-black">Commentaires</label>
            <textarea
              id="commentary"
              formControlName="commentary"
              class="form-control"
              rows="4"
              placeholder="Vos commentaires ou questions"
            ></textarea>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-primary px-5">Confirmer la réservation</button>
          </div>
        </form>
      </div>
    </ng-container>

    <!-- Si l'utilisateur doit entrer le code de confirmation -->
    <ng-container *ngIf="!confirmed && showConfirmationInput">
      <div class="text-center mb-4">
        <h4 class="text-black">
          Entrez le code de confirmation reçu par email pour valider votre réservation. Ce code expirera dans 10 minutes. Pensez à vérifier vos courriels indésirables.
        </h4>
        <input
        type="text"
        [(ngModel)]="confirmationCode"
        class="form-control mb-3 mx-auto text-center"
        style="max-width: 150px;"
        placeholder="Code"
        maxlength="6"
        pattern="[0-9]*"
        inputmode="numeric"
        >
        <button (click)="confirmBooking()" class="btn btn-primary px-5">Valider</button>
      </div>
    </ng-container>
    
    <!-- Si la réservation est confirmée, afficher le résumé du rendez-vous -->
    <ng-container *ngIf="confirmed">
      <div class="text-center">
        <h3 class="text-success">Félicitations, votre réservation est confirmée !</h3>
        <p class="text-black">
          Voici les détails de votre rendez-vous :
        </p>
        <ul class="list-unstyled">
          <li><strong>Date :</strong> {{ appointmentSummary?.date | date: 'dd-MM-yyyy' }}</li>
          <li><strong>Heure :</strong> {{ appointmentSummary?.heure }}</li>
          <li><strong>Durée :</strong> {{ appointmentSummary?.duration }} minutes</li>
        </ul>
      </div>
    </ng-container>
  </div>
</section>
