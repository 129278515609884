    <div class="error-page">
        <div class="container">
            <div class="text-center p-5 my-auto">
                <div class="row align-items-center justify-content-center h-100">
                    <div class="col-xl-7">
                        <p class="error-text mb-sm-0 mb-2">404</p>
                        <p class="fs-18 fw-semibold mb-3">Oops 😭 , la page que vous recherchez n'est plus disponible.</p>
                        <div class="row justify-content-center mb-5">
                            <div class="col-xl-6">
                                <p class="mb-0 op-7">Nous sommes désolés, la page à laquelle vous essayez d'accéder a été désactivée ou n'a jamais existée.</p>
                            </div>
                        </div>
                        <a class="btn btn-primary"><i class="ri-arrow-left-line align-middle me-1 d-inline-block"></i>Retour</a>
                    </div>
                </div>
            </div>
        </div>
    </div>